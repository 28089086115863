import React from 'react'
import ProductAbout from '../../../Components/ProductDetail/ProductAbout'

const ProductsDetail = () => {
    return (
        <div className='p-10 w-[95vw] flex items-center justify-between gap-10 pt-20'>
            <ProductAbout />
        </div>
    )
}

export default ProductsDetail
