import React from 'react'
import SecondaryButton from './Button/SecondaryButton'

const HelpCenterCard = ({ img, text }) => {
    return (
        <div className='shadow-center p-3 w-[25vw] h-[65vh] rounded-lg flex flex-col items-start justify-center gap-5 max-md:w-[90vw] max-md:h-[max-content]'>
            <p className='text-[#838383]'>Тусламж</p>
            <img className='w-[20vw] h-[40vh] object-contain self-center max-md:w-full'
                src={img} alt="" />
            <h2 className='text-[17px] w-[15vw] font-semibold max-md:w-full'>{text}</h2>
            <div className='self-end'>
                <SecondaryButton text={'Унших'} />
            </div>
        </div>
    )
}

export default HelpCenterCard
