import React from 'react'
import help1 from '../../Assets/help1.png'
import help2 from '../../Assets/help2.png'
import help3 from '../../Assets/contactmeet.png'
import help4 from '../../Assets/contacthowto.png'
import HelpCenterCard from '../../Utills/HelpCenterCard'

const ContactCards = () => {

    const datas = [
        {
            title: 'Алийг нь сонгохоо мэдэхгүй байвал',
            img: help1,
        },
        {
            title: 'Сонгосон багцаа сольхыг хүсвэл',
            img: help2,
        },
        {
            title: 'Захиалгаар ажил хийлгэх',
            img: help3,
        },
        {
            title: 'Хэрхэн Вебсайт түрээслэх вэ ?',
            img: help4,
        },
    ]

    return (
        <div className='flex flex-wrap gap-10 justify-center items-center w-[60vw] max-md:overflow-x-scroll max-md:w-full max-md:p-2 '>
            {datas.map((e) => (
                <HelpCenterCard img={e.img} text={e.title} />
            ))}
        </div>
    )
}

export default ContactCards
