import React from 'react'

const Rating = () => {
    return (
        <div>
            <div className='w-[100px] border-[3px] border-[#d3d3d3] rounded-xl'></div>
            <div className='w-[80px] border-[2px] border-[#333] mt-[-5px] rounded-xl absolute'></div>
        </div>
    )
}

export default Rating
