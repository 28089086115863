import React from 'react'
import ContactForm from '../../Components/Contact/ContactForm'
import ContactCards from '../../Components/Contact/ContactCards'

const ContactPage = () => {
    return (
        <div className='p-10 w-[95vw] flex items-start justify-center gap-10 pt-20 max-md:flex-col max-md:px-0'>
            <ContactForm />
            <ContactCards />
        </div>
    )
}

export default ContactPage
