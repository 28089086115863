import React from 'react'
import Rating from './Rating'

const ShopProductCard = () => {
    return (
        <div className='w-[15vw] flex flex-col gap-2 max-md:w-[45vw] shadow-sm'>
            <img className='w-full rounded-lg' src="https://images.ctfassets.net/lzny33ho1g45/5VGvMMdX169JCk60IJCRDA/358a2d3f62c6028d978efc382cb83ad5/image10.png?w=1400" alt="" />
            <p className='text-[#838383] max-md:text-[13px]'>Танилцуулга вебсайт</p>
            <p className='font-semibold text-[21px] max-md:text-[16px]'>Бизнес танилцуулга вебсайт</p>
            <div className="divider"></div>
            <div className='flex gap-5 justify-start items-center'>
                <Rating />
                <p className='text-[18px] text-[#22BDFF]'>4,2</p>
                {window.innerWidth > 720 ? <p className='text-[14px] text-[#838383]'>(12 сэтгэгдэл)</p> : null}
            </div>
        </div>
    )
}

export default ShopProductCard
