import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineSearch } from "react-icons/md";
import { MdFavoriteBorder } from "react-icons/md";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { FiShoppingCart } from "react-icons/fi";
import { CiMenuBurger } from "react-icons/ci";
import { IoClose } from "react-icons/io5";

const Navbar = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 720) {
            setIsOpen(true);
        }
    })

    return (
        <div className='flex justify-center items-center w-screen fixed top-0 z-10 bg-white'>
            <div className='flex justify-between w-[90vw] items-center self-center py-2'>
                {window.innerWidth < 720 && isOpen ? <IoClose size={30} onClick={() => setIsOpen(false)} /> : null}
                {isOpen ? <div className='flex gap-4 navbar-mobile'>
                    <Link to={'/'} className={currentPage === 0 ? 'active-nav' : ''} onClick={() => {
                        setCurrentPage(0);
                        setIsOpen(false);
                    }}>
                        Нүүр
                    </Link>
                    <Link to={'/shop'} className={currentPage === 1 ? 'active-nav' : ''} onClick={() => {
                        setCurrentPage(1);
                        setIsOpen(false);
                    }}>
                        Дэлгүүр
                    </Link>
                    <Link to={'/price'} className={currentPage === 2 ? 'active-nav' : ''} onClick={() => {
                        setCurrentPage(2);
                        setIsOpen(false);
                    }}>
                        Үнэ
                    </Link>
                    <Link to={'/contact'} className={currentPage === 3 ? 'active-nav' : ''} onClick={() => {
                        setCurrentPage(3);
                        setIsOpen(false);
                    }}>
                        Холбоо барих
                    </Link>
                </div> : <CiMenuBurger size={30} onClick={() => setIsOpen(true)} />}
                <div className='flex gap-4 bg-[#333] items-center justify-center px-5 py-2 rounded-3xl'>
                    <Link to={'/search'}><MdOutlineSearch size={30} color='#22BDFF' /></Link>
                    <Link to={'/profile'}><MdFavoriteBorder size={30} color='#22BDFF' /></Link>
                    <Link to={'/login'}><HiOutlineUserCircle size={30} color='#22BDFF' /></Link>
                    <Link to={'/profile'}><FiShoppingCart size={30} color='#22BDFF' /></Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar
