import React from 'react'
import support from '../../Assets/support.png'
import SecondaryButton from '../../Utills/Button/SecondaryButton'
import fb from '../../Assets/fb.png'
import x from '../../Assets/x.png'
import ig from '../../Assets/ig.png'
import google from '../../Assets/google.png'
import linkidIn from '../../Assets/in.png'
import mail from '../../Assets/mail.svg'
import location from '../../Assets/location.svg'
import phone from '../../Assets/phone.svg'
import { MdCopyright } from "react-icons/md";

const ContactForm = () => {

    const socialMedias = [
        {
            icon: fb,
            link: '',
        },
        {
            icon: google,
            link: '',
        },
        {
            icon: x,
            link: '',
        },
        {
            icon: ig,
            link: '',
        },
        {
            icon: linkidIn,
            link: '',
        },
    ];

    const infos = [
        {
            text: 'infotemplatemn@gmail.com',
            icon: mail,
        },
        {
            text: '+976 60071001',
            icon: phone,
        },
        {
            text: 'Чингэлтэй дүүрэг 4-р хороо 9-р байр',
            icon: location,
        },
    ]

    return (
        <div className='shadow-center w-[25vw] rounded-lg flex px-10 py-5 flex-col items-center justify-start gap-5 min-h-[70vh] max-md:w-full'>
            <img src={support} className='w-[15vw] max-md:w-[30vw]' alt="" />
            <div className='flex gap-3 w-full items-end justify-center'>
                <label>
                    <p>Хүсэлтийн гарчиг:</p>
                    <input type="text" name="" id="" />
                </label>
                <label>
                    <p>Нэр:</p>
                    <input type="text" name="" id="" />
                </label>
            </div>
            <label className='w-full'>
                <p>И-Мэйл:</p>
                <input className='w-full' type="text" name="" id="" />
            </label>
            <label className='w-full'>
                <p>Утасны дугаар:</p>
                <input type="text" name="" id="" />
            </label>
            <label>
                <p>Мессэж:</p>
                <textarea name="" id="" cols={30} rows={5}></textarea>
            </label>
            <SecondaryButton width={window.innerWidth > 720 ? '15vw' : '30vw'} text={'Илгээх'} />
            <div className='pt-5 flex items-center justify-between w-full'>
                {socialMedias.map((e) => (
                    <a href={e.link}>
                        <img src={e.icon} className='w-[2vw] h-[4vh] max-md:w-[6vw]' alt="" />
                    </a>
                ))}
            </div>
            <div className='w-full'>
                <div className="divider"></div>
            </div>
            <div className='flex flex-col items-start justify-start gap-3 max-md:items-start'>
                {infos.map((e) => (
                    <div className='flex gap-2 justify-center items-center'>
                        <img src={e.icon} className='w-[36px] h-[36px]' alt="" />
                        <p className='text-[#838383] px-3'>{e.text}</p>
                    </div>
                ))}
            </div>
            <div className='w-full'>
                <div className="divider"></div>
            </div>
            <div className='flex gap-2'>
                <MdCopyright color='#838383' size={24} />
                <p className='text-[#838383]'>Matrix Solution Labs</p>
            </div>
        </div>
    )
}

export default ContactForm
