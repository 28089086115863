import React from 'react'

const SecondaryButton = ({ text, width, color }) => {
    return (
        <button style={{ width: width ? width : '', color: color ? color : '#22BDFF' }}
            className='bg-[#fff] shadow-center rounded-lg py-1 px-4  font-semibold max-md:text-[13px]'>
            <p>{text}</p>
        </button>
    )
}

export default SecondaryButton
