import React from 'react'
import ManyImage from '../../Utills/ManyImage'
import SecondaryButton from '../../Utills/Button/SecondaryButton'
import PrimaryButton from '../../Utills/Button/PrimaryButton'
import { MdFavoriteBorder } from "react-icons/md";

const ProductAbout = () => {
    return (
        <div className='flex gap-10'>
            <ManyImage />
            <div className='w-[40vw] flex flex-col gap-5'>
                <div>
                    <h2 className='text-[30px] font-semibold'>Бизнес танилцуулга сайт</h2>
                    <div className='flex gap-3'>
                        <p>*</p>
                        <p>*</p>
                        <p>*</p>
                        <p>*</p>
                        <p>*</p>
                        <p className='text-[#22BDFF]'>4,2</p>
                        <p className='text-[#838383] text-[14px]'>(12 сэтгэгдэл)</p>
                    </div>
                </div>
                <div>
                    <p className='text-[#838383] text-[14px]'>Худалдан авах үнэ</p>
                    <h2 className='text-[32px] font-bold'>1,200,000</h2>
                </div>
                <SecondaryButton text={'Тест хувилбар үзэх'} width={'full'} color={'#333'} />
                <div>
                    <div className='w-full flex gap-2 justify-between'>
                        <PrimaryButton text={'Түрээслэх'} width={'90%'} bg={'#333'} />
                        <div className='bg-[#333] rounded-lg p-2 self-center'>
                            <MdFavoriteBorder size={20} color='#fff' />
                        </div>
                    </div>
                    <p className='py-2 text-[#333] text-[14px]'>Илүү их бэлэг, Илүү хямд</p>
                </div>
                <p className='text-[15px]'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor, recusandae eius est sunt architecto praesentium vero, officia placeat impedit saepe iure aliquid beatae, doloribus accusamus veritatis fugiat eveniet sed facere odit doloremque. Temporibus voluptates magnam sint harum totam laborum velit quasi nihil inventore nam blanditiis optio eligendi vel ex odit est, consequuntur natus, cupiditate repellat maxime itaque maiores dolorem perferendis aut. Unde modi sunt impedit sit voluptates consequuntur magnam quia laboriosam quos odio ullam itaque a quis fugiat, asperiores fugit?</p>
                <div>
                    <h2 className='text-[21px] font-semibold'>Вебсайт дээрх үйлдэлүүд</h2>
                    <p className='text-[14px] text-[#333] px-5'>-Админ хэсэг</p>
                    <p className='text-[14px] text-[#333] px-5'>-Влог хуудас</p>
                    <p className='text-[14px] text-[#333] px-5'>-Бидний ажилууд хуудас</p>
                    <p className='text-[14px] text-[#333] px-5'>-Мэдээлэл орох хуудас</p>
                </div>
            </div>
        </div>
    )
}

export default ProductAbout
