import React from 'react'

const ProfileCategory = ({ selectedIndex, setSelectedIndex }) => {

    const titles = [
        {
            title: 'Түрээслэж байгаа вебсайт',
            index: 0
        },
        {
            title: 'Идвэхтэй нэрийн хуудаснууд',
            index: 1
        },
        {
            title: 'Идвэхтэй багц',
            index: 2
        },
        {
            title: 'Хадгалсан вебсайт',
            index: 3
        },
    ]

    const active = 'font-semibold border-b-[2px] border-b-[#22BDFF]';
    const unactive = '';

    return (
        <div className='flex justify-start items-center w-full gap-4 max-md:overflow-y-scroll '>
            {titles.map((e) => (
                <button onClick={() => setSelectedIndex(e.index)} className={selectedIndex === e.index ? active : unactive}>{e.title}</button>
            ))}
        </div>
    )
}

export default ProfileCategory
