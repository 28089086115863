import React from 'react'

const PrimaryButton = ({ text, width, bg }) => {
    return (
        <button style={{ width: width ? width : '', background: bg ? bg : '#22BDFF' }}
            className=' rounded-md py-1 px-4 text-white font-semibold max-md:px-2 max-md:text-[15px] max-md:py-1'>
            <p>{text}</p>
        </button>
    )
}

export default PrimaryButton
