import React from 'react'
import PrimaryButton from '../../Utills/Button/PrimaryButton'

const UserInfo = () => {
    return (
        <div className='flex w-full justify-center items-center gap-5'>
            <img className='w-[150px] h-[150px] rounded-[100%] p-0' src="https://www.svgrepo.com/show/420360/avatar-batman-comics.svg" alt="" />
            <div className='flex flex-col gap-5'>
                <h2 className='text-xl font-semibold'>Zolbootbaatar</h2>
                <h2 className='text-sm text-[#838383]'>ozolbootbaatar@gmail.com</h2>
                <PrimaryButton bg={'#333'} text={'Мэдээлэл солих'} />
            </div>
        </div>
    )
}

export default UserInfo
