import React from 'react';
import { FaStar } from "react-icons/fa";
import PrimaryButton from './Button/PrimaryButton';
import { CiShoppingCart } from "react-icons/ci";
import { Link } from 'react-router-dom';

const ProductCard = () => (
    <div className="border-[1px] border-[#22BDFF] p-3 w-[15vw] h-[35vh] rounded-lg flex flex-col justify-center items-start gap-4
    max-md:w-[90vw] max-md:h-[40vh]">
        <img className='border-[1px] border-[#22BDFF] p-3 w-[15vw] rounded-lg h-[15vh] self-center max-md:w-[90vw] max-md:h-[20vh]'
            src="https://cdn5.vectorstock.com/i/1000x1000/90/34/web-development-project-team-webpage-building-vector-28959034.jpg" alt="" />
        <h2>Танилцуулга Вебсайт</h2>
        <div className='flex justify-center items-center gap-2'>
            <FaStar size={23} color='#22BDFF' />
            <p className='text-[#22BDFF]'>4,2</p>
            <p className='text-[#838383] text-[14px]'>(12 сэтгэгдэл)</p>
        </div>
        <div className='flex justify-between items-center w-full max-md:w-[80vw]'>
            <Link to={'/product/s'}>
                <PrimaryButton width={window.innerWidth < 720 ? '30vw' : '8vw'} text={'Зочлох'} />
            </Link>
            <div className='bg-[#333] p-1 rounded-full'>
                <CiShoppingCart size={30} color='#22BDFF' />
            </div>
        </div>
    </div>
);

export default ProductCard;
