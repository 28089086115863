import React, { useState } from 'react'
import PriceCategories from '../../Components/Price/PriceCategories'
import PricePlan from '../../Components/Price/PricePlan';

const PricePage = () => {
    const [isActive, setIsActive] = useState(0);

    return (
        <div className='p-10 w-[95vw] min-h-screen flex flex-col items-start justify-start gap-5 pt-20 max-md:px-0'>
            <h2 className='text-[25px] font-semibold'>Өөрийн нөхцөлд таарах багц аа сонгоно уу</h2>
            <div className='w-[90vw]'>
                <div className="divider"></div>
            </div>
            <PriceCategories isActive={isActive} setIsActive={setIsActive} />
            <PricePlan />
        </div>
    )
}

export default PricePage
