import React from 'react'
import LoginBanner from '../../Utills/Banner/LoginBanner'
import LoginForm from '../../Components/Login/LoginForm'

const LoginPage = () => {
    return (
        <div className='p-10 w-[95vw] flex items-center justify-between gap-10 pt-20 max-md:p-0'>
            <LoginForm />
            {window.innerWidth > 720 ? <LoginBanner /> : null}
        </div>
    )
}

export default LoginPage
