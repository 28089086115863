import React from 'react'

const PriceCategories = ({ isActive, setIsActive }) => {
    return (
        <div className='flex gap-5 justify-start items-center max-md:w-[90vw] max-md:overflow-x-scroll max-md:p-3'>
            <button onClick={() => setIsActive(0)}
                className={isActive === 0 ? 'text-[#22BDFF] border-b-2 border-[#22BDFF]' : 'text-[#838383]'}>
                Танилцуулга Вебсайт
            </button>
            <button onClick={() => setIsActive(1)}
                className={isActive === 1 ? 'text-[#22BDFF] border-b-2 border-[#22BDFF]' : 'text-[#838383]'}>
                Онлайн худалдааны Вебсайт
            </button>
            <button onClick={() => setIsActive(2)}
                className={isActive === 2 ? 'text-[#22BDFF] border-b-2 border-[#22BDFF]' : 'text-[#838383]'}>
                Влог Вебсайт
            </button>
            <button onClick={() => setIsActive(3)}
                className={isActive === 3 ? 'text-[#22BDFF] border-b-2 border-[#22BDFF]' : 'text-[#838383]'}>
                Контент Вебсайт
            </button>
        </div>
    )
}

export default PriceCategories
