import React from 'react'
import PricePlanCard from '../../Utills/PricePlanCard'

const AvailablePlan = () => {
    const data =
    {
        isBest: true,
        mount: '12',
        price: '53,000',
        products: [
            {
                productName: 'Вебсайт',
                productItems: [
                    {
                        item: 'Хүссэн өнгө нэмэх',
                    },
                    {
                        item: 'Өөрсдийн мэдээллийг нэмэх',
                    },
                    {
                        item: 'Удирдлагын хэсэг',
                    },
                    {
                        item: 'Анализ хэсэг',
                    },
                    {
                        item: 'Модератор нэмэх хэсэг',
                    },
                    {
                        item: '10% хүссэн өөрчлөлт эсвэл нэмэлт хийлгэх',
                    },
                ],
            },
            {
                productName: 'Нэрийн хуудас',
                productItems: [
                    {
                        item: '5 хүний QR нэрийн хуудас',
                    },
                    {
                        item: 'Нэмэлт нэрийн хуудасны худалдан авалт 40% хямдрал',
                    },
                    ,
                    {
                        item: 'QR нэрийн хуудасны арын өнгийг солиулах эрх',
                    },
                ],
            }
        ],
        colorfrom: '#8380FE',
        colorto: '#FF4848',
    };

    return (
        <div className='flex w-full justify-start'>
            <PricePlanCard isBest={data.isBest} price={data.price} mount={data.mount} products={data.products} colorfrom={data.colorfrom} colorto={data.colorto} />
        </div>
    )
}

export default AvailablePlan
