import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import ShopPage from './Pages/Shop/Shop';
import PricePage from './Pages/Price/Price';
import ContactPage from './Pages/Contact/Contact';
import LoginPage from './Pages/Login/Login';
import SignUpPage from './Pages/SignUp/SignUp';
import ProductsDetail from './Pages/Products/ProductsDetail/ProductsDetail';
import ProfilePage from './Pages/Profile/ProfilePage';
import SearchPage from './Pages/Search/SearchPage';

function App() {
  // const user = window.localStorage.getItem('userId');
  // const [partners, setPartners] = useState([]);
  // const userInfo = JSON.parse(window.localStorage.getItem('usersnk'));
  // const [isLoading, setIsLoading] = useState(true);
  // const [isNullOrder, setIsNullOrder] = useState(false);

  // useEffect(() => {
  //     if (isLoading && userInfo) {
  //         // alert('s');
  //         axios.get('http://localhost:8000/partner')
  //             .then((e) => { setPartners(e.data.data); setIsLoading(false) })
  //             .catch((e) => console.log(e));
  //     }
  //     if (userInfo === null && partners.length === 0) {
  //         // alert('a')
  //         setIsLoading(false)
  //     }

  //     if (userInfo !== null && partners.length === 0 && !isNullOrder) {
  //         setIsNullOrder(true);
  //         // alert('w')
  //         setIsLoading(true);
  //     }
  // }, [isLoading, partners]);


  return (
    <div className='flex justify-center items-center flex-col'>
      <Router>
        {/* {userInfo !== null ? <Sidebar /> : ''} */}
        <Navbar />
        {/* {isLoading ? <div className="loader-container"><div className='loader'></div></div> : */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/shop' element={<ShopPage />} />
          <Route path='/price' element={<PricePage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup' element={<SignUpPage />} />
          <Route path='/product/:id' element={<ProductsDetail />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/search' element={<SearchPage />} />
        </Routes>
        {/* } */}
      </Router>
    </div>
  );
}

export default App;
