import React from 'react'
import SignUpForm from '../../Components/Login/SignUpForm'
import LoginBanner from '../../Utills/Banner/LoginBanner'

const SignUpPage = () => {
    return (
        <div className='p-10 w-[95vw] flex items-center justify-between gap-10 pt-20 max-md:px-0'>
            <SignUpForm />
            {window.innerWidth > 720 ? <LoginBanner /> : null}
        </div>
    )
}

export default SignUpPage
