import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const ManyImage = () => {
    return (
        <div>
            <img className='w-[40vw] border-2' src="https://images.ctfassets.net/lzny33ho1g45/5VGvMMdX169JCk60IJCRDA/358a2d3f62c6028d978efc382cb83ad5/image10.png?w=1400" alt="" />
            <Swiper
                spaceBetween={10}
                slidesPerView={4}
                navigation={true}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                onSlideChange={() => console.log('slide change')}
                className="w-[40vw] px-10"
            >
                <SwiperSlide className=''>
                    <img className='w-[10vw]' src="https://images.ctfassets.net/lzny33ho1g45/5VGvMMdX169JCk60IJCRDA/358a2d3f62c6028d978efc382cb83ad5/image10.png?w=1400" alt="" />
                    <div className='w-[10vw] h-[10vh] bg-black absolute bottom-0 opacity-40'></div>
                </SwiperSlide>
                <SwiperSlide className=''>
                    <img className='w-[10vw]' src="https://images.ctfassets.net/lzny33ho1g45/5VGvMMdX169JCk60IJCRDA/358a2d3f62c6028d978efc382cb83ad5/image10.png?w=1400" alt="" />
                    <div className='w-[10vw] h-[10vh] bg-black absolute bottom-0 opacity-40'></div>
                </SwiperSlide>
                <SwiperSlide className=''>
                    <img className='w-[10vw]' src="https://images.ctfassets.net/lzny33ho1g45/5VGvMMdX169JCk60IJCRDA/358a2d3f62c6028d978efc382cb83ad5/image10.png?w=1400" alt="" />
                    <div className='w-[10vw] h-[10vh] bg-black absolute bottom-0 opacity-40'></div>
                </SwiperSlide>
                <SwiperSlide className=''>
                    <img className='w-[10vw]' src="https://images.ctfassets.net/lzny33ho1g45/5VGvMMdX169JCk60IJCRDA/358a2d3f62c6028d978efc382cb83ad5/image10.png?w=1400" alt="" />
                    <div className='w-[10vw] h-[10vh] bg-black absolute bottom-0 opacity-40'></div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default ManyImage
