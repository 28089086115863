import React from 'react'
import PrimaryButton from './Button/PrimaryButton'
import SecondaryButton from './Button/SecondaryButton'

const SwiperCard = () => {
    return (
        <div className='flex flex-col items-center justify-center max-md:items-start max-md:justify-start 
        max-md:w-[90vw] max-md:h-[30vh] max-md:rounded-md max-md:flex-wrap'>
            <img className='w-[90vw] h-[60vh] rounded-3xl max-md:w-[90vw] max-md:h-[30vh] max-md:rounded-md'
                src="https://cdn5.vectorstock.com/i/1000x1000/90/34/web-development-project-team-webpage-building-vector-28959034.jpg" alt="" />
            <div className='bg-black opacity-60 w-[90vw] h-[60vh] absolute rounded-3xl 
            max-md:h-[30vh] max-md:rounded-md  max-md:w-[90vw]'></div>
            <div className='flex flex-col items-start justify-center gap-10 absolute left-0 p-20 max-md:p-10 '>
                <h2 className='text-[50px] font-semibold opacity-none text-white w-[35vw] 
                max-md:w-[50vw] max-md:text-[20px]'>Гайхамшиг мэт
                    <span className='text-[#22BDFF]'> 3D </span>Моделийг мэдэр
                </h2>
                <p className='text-white opacite opacity-100 w-[40vw] max-md:text-[14px] max-md:hidden'>
                    Та захиалгаар өөрийн Вебсайт дээр нэмэлтээр хийлгэх боломжтой ирээдүйг харуулах мэт веб тэй болмоор байна уу
                </p>
                <div className='flex gap-3'>
                    <PrimaryButton text={'Дэлгэрэнгүй'} />
                    <SecondaryButton text={'Захиалга өгөх'} />
                </div>
            </div>
        </div>
    )
}

export default SwiperCard
