import React from 'react'
import PricePlanCard from '../../Utills/PricePlanCard'

const PricePlan = () => {

    const datas = [
        {
            isBest: false,
            mount: '1',
            price: '75,000',
            products: [
                {
                    productName: 'Вебсайт',
                    productItems: [
                        {
                            item: 'Хүссэн өнгө нэмэх',
                        },
                        {
                            item: 'Өөрсдийн мэдээллийг нэмэх',
                        },
                        {
                            item: 'Удирдлагын хэсэг',
                        },
                    ],
                },
                {
                    productName: 'Нэрийн хуудас',
                    productItems: [
                        {
                            item: '1 хүний QR нэрийн хуудас',
                        },
                        {
                            item: 'Нэмэлт нэрийн хуудасны худалдан авалт 20% хямдрал',
                        },
                    ],
                }
            ],
            colorfrom: '#22BDFF',
            colorto: '#91FFDE',
        },
        {
            isBest: false,
            mount: '3',
            price: '69,000',
            products: [
                {
                    productName: 'Вебсайт',
                    productItems: [
                        {
                            item: 'Хүссэн өнгө нэмэх',
                        },
                        {
                            item: 'Өөрсдийн мэдээллийг нэмэх',
                        },
                        {
                            item: 'Удирдлагын хэсэг',
                        },
                    ],
                },
                {
                    productName: 'Нэрийн хуудас',
                    productItems: [
                        {
                            item: '2 хүний QR нэрийн хуудас',
                        },
                        {
                            item: 'Нэмэлт нэрийн хуудасны худалдан авалт 30% хямдрал',
                        },
                    ],
                }
            ],
            colorfrom: '#22BDFF',
            colorto: '#5D6DFF',
        },
        {
            isBest: false,
            mount: '6',
            price: '62,000',
            products: [
                {
                    productName: 'Вебсайт',
                    productItems: [
                        {
                            item: 'Хүссэн өнгө нэмэх',
                        },
                        {
                            item: 'Өөрсдийн мэдээллийг нэмэх',
                        },
                        {
                            item: 'Удирдлагын хэсэг',
                        },
                        {
                            item: 'Анализ хэсэг',
                        },
                        {
                            item: 'Модератор нэмэх хэсэг',
                        },
                    ],
                },
                {
                    productName: 'Нэрийн хуудас',
                    productItems: [
                        {
                            item: '4 хүний QR нэрийн хуудас',
                        },
                        {
                            item: 'Нэмэлт нэрийн хуудасны худалдан авалт 40% хямдрал',
                        },
                    ],
                }
            ],
            colorfrom: '#22BDFF',
            colorto: '#FFEF5D',
        },
        {
            isBest: true,
            mount: '12',
            price: '53,000',
            products: [
                {
                    productName: 'Вебсайт',
                    productItems: [
                        {
                            item: 'Хүссэн өнгө нэмэх',
                        },
                        {
                            item: 'Өөрсдийн мэдээллийг нэмэх',
                        },
                        {
                            item: 'Удирдлагын хэсэг',
                        },
                        {
                            item: 'Анализ хэсэг',
                        },
                        {
                            item: 'Модератор нэмэх хэсэг',
                        },
                        {
                            item: '10% хүссэн өөрчлөлт эсвэл нэмэлт хийлгэх',
                        },
                    ],
                },
                {
                    productName: 'Нэрийн хуудас',
                    productItems: [
                        {
                            item: '5 хүний QR нэрийн хуудас',
                        },
                        {
                            item: 'Нэмэлт нэрийн хуудасны худалдан авалт 40% хямдрал',
                        },
                        ,
                        {
                            item: 'QR нэрийн хуудасны арын өнгийг солиулах эрх',
                        },
                    ],
                }
            ],
            colorfrom: '#8380FE',
            colorto: '#FF4848',
        },
    ]

    return (
        <div className='flex justify-between items-start gap-10 w-[90vw] max-md:overflow-x-scroll max-md:w-[90vw] max-md:p-5'>
            {datas.map((e) => (
                <PricePlanCard isBest={e.isBest} price={e.price} mount={e.mount} products={e.products} colorfrom={e.colorfrom} colorto={e.colorto} />
            ))}
        </div>
    )
}

export default PricePlan
