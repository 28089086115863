import React from 'react'
import { CiSearch } from "react-icons/ci";

const SearchHeader = () => {
    return (
        <div className='bg-[#22BDFF] w-full rounded-xl p-5 flex flex-col gap-4'>
            <h2 className='text-2xl text-white font-semibold'>Өөрийн хүссэн вебсайтаа хайна уу </h2>
            <div className='flex justify-start items-center gap-3'>
                <input className='w-[25vw] rounded-2xl border-none text-md px-3 py-1' placeholder='Хайх сайтаа бичнэ үү...' type="text" name="" id="" />
                <CiSearch className='rounded-full p-1 bg-white' size={30} />
            </div>
        </div>
    )
}

export default SearchHeader
