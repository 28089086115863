import { Box, Slider } from '@mui/material';
import React, { useState } from 'react';
import { ImCheckboxUnchecked } from "react-icons/im";

function valuetext(value) {
    return `${value}°C`;
}

const ShopSidebar = () => {
    const [value, setValue] = useState([0, 20000000]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='min-h-[70vh] w-[20vw] items-start flex flex-col bg-[#f9f9f9] rounded-md p-2 gap-5'>
            <h2 className='text-lg font-semibold pb-3'>Ангилал</h2>
            <div className='flex items-center gap-2 pl-2'>
                <ImCheckboxUnchecked />
                <p className='text-sm '>Танилцуулга</p>
            </div>
            <p>Хамгийн багадаа: {value[0]} - Хамгийн ихдээ: {Intl.NumberFormat('en-us').format(value[1])}</p>
            <Box sx={{ width: 300, padding: '10px' }}>
                <Slider
                    getAriaLabel={() => 'Price range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    max={100000}
                    color='primary'
                />
            </Box>
        </div>
    );
};

export default ShopSidebar;
