import React from 'react'

const PricePlanCard = ({ mount, products, colorfrom, colorto, price, isBest }) => {
    return (
        <div className='shadow-center rounded-lg min-h-[80vh] w-[20vw] flex flex-col justify-start items-center max-md:w-[90vw]'>
            {isBest ? <p className='bg-red-600 w-full rounded-t-lg px-3 text-center text-white text-[14px] py-1 font-semibold'>
                Хамгийн их худалдан авалттай багц
            </p> : null}
            <p
                className={`text-[23px] font-semibold ${isBest ? 'rounded-b-md rounded-t-none' : 'rounded-md'} px-14 py-2 text-center w-full`}
                style={{
                    background: `linear-gradient(to right, ${colorfrom}, ${colorto})`,
                    color: '#fff',
                }}>
                {mount} сарын багц
            </p>
            <div className='flex flex-col justify-between gap-24 items-center min-h-[70vh] max-md:w-[65vw]'>
                <div className='p-2'>
                    {products.map((e, idx) => (
                        <div key={idx} className='flex flex-col items-start justify-center gap-1'>
                            <p className='text-[14px] text-[#838383]'>{e.productName}</p>
                            {e.productItems.map((el, subIdx) => (
                                <p key={subIdx} className='text-[#333] text-[15px] w-full font-medium pl-2'>-{el.item}</p>
                            ))}
                            <div className="w-[18vw] mb-5">
                                <div className="divider"></div>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <p className='text-[#22BDFF] mb-5 rounded-md shadow-center text-[17px] font-medium text-center w-[15vw] max-md:w-full max-md:px-3'>
                        {price} / сар
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PricePlanCard
