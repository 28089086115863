import React from 'react'
import PrimaryButton from '../../Utills/Button/PrimaryButton'
import SecondaryButton from '../../Utills/Button/SecondaryButton'
import { Link } from 'react-router-dom'

const LoginForm = () => {
    return (
        <div className='flex flex-col w-[40vw] gap-5 justify-center items-center max-md:w-full max-md:h-screen'>
            <h2 className='text-[22px] font-semibold w-[50%] self-end text-[#22BDFF] max-md:w-[70%]'>Тавтай морил!</h2>
            <div className='w-[50%] self-center flex flex-col gap-5 justify-center items-center max-md:w-[70%]'>
                <div className='w-full'>
                    <p>И-мэйл:</p>
                    <input type="text" className='p-2' placeholder='И-мэйл' name="" id="" />
                </div>
                <div className='w-full'>
                    <p>Нууц үг:</p>
                    <input type="password" className='p-2' placeholder='Нууц үг' name="" id="" />
                </div>
            </div>
            <div className='w-[50%] flex items-end justify-end max-md:w-[70%]'>
                <p className='w-[max-content] self-end border-b-2 border-[#333]'>Нууц үг мартсан</p>
            </div>
            <PrimaryButton text={'Нэвтрэх'} width={window.innerWidth > 720 ? '50%' : '70%'} />
            <div className='flex gap-2 justify-center items-center w-[40%] max-md:w-[70%]'>
                <div className="divider w-[40%]"></div>
                <p>Эсвэл</p>
                <div className="divider w-[40%]"></div>
            </div>
            <Link to={'/signup'} className='flex self-center items-center justify-center w-full'>
                <SecondaryButton text={'Бүртгүүлэх'} width={window.innerWidth > 720 ? '50%' : '70%'} />
            </Link>
        </div>
    )
}

export default LoginForm
