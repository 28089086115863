import React from 'react'
import { IoBagAddOutline } from "react-icons/io5";

const OwnedWeb = () => {
    return (
        <div className='flex flex-wrap justify-between items-center w-full gap-5 max-md:gap-1'>
            <img className='w-[25vw] h-[30vh] max-md:w-[42vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
            <img className='w-[25vw] h-[30vh] max-md:w-[42vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
            <img className='w-[25vw] h-[30vh] max-md:w-[42vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
            <div className='w-[25vw] h-[30vh] max-md:w-[42vw] max-md:h-[15vh] border p-1 rounded-2xl bg-[#f0f0f0] flex justify-center items-center'>
                <IoBagAddOutline size={40} />
            </div>
        </div>
    )
}

export default OwnedWeb
