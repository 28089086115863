import React from 'react'
import { IoHeart } from "react-icons/io5";

const SavedWeb = () => {
    return (
        <div className='flex flex-wrap justify-between items-center w-full gap-5 max-md:justify-center'>
            <div className=''>
                <img className='w-[25vw] h-[30vh] max-md:w-[40vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
                <div className='bg-[#ff8f8f] rounded-full p-1 absolute mt-[-50px] ml-[22vw] max-md:ml-[28vw]'>
                    <IoHeart size={30} color='red' />
                </div>
            </div>
            <div className=''>
                <img className='w-[25vw] h-[30vh] max-md:w-[40vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
                <div className='bg-[#ff8f8f] rounded-full p-1 absolute mt-[-50px] ml-[22vw] max-md:ml-[28vw]'>
                    <IoHeart size={30} color='red' />
                </div>
            </div> <div className=''>
                <img className='w-[25vw] h-[30vh] max-md:w-[40vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
                <div className='bg-[#ff8f8f] rounded-full p-1 absolute mt-[-50px] ml-[22vw] max-md:ml-[28vw]'>
                    <IoHeart size={30} color='red' />
                </div>
            </div> <div className=''>
                <img className='w-[25vw] h-[30vh] max-md:w-[40vw] max-md:h-[15vh] border p-1 rounded-2xl' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHS5CXTh7ChNwuULVuA_BhYXisoEnheC9Sog&s" alt="" />
                <div className='bg-[#ff8f8f] rounded-full p-1 absolute mt-[-50px] ml-[22vw] max-md:ml-[28vw]'>
                    <IoHeart size={30} color='red' />
                </div>
            </div>
        </div>
    )
}

export default SavedWeb
