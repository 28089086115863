import React from 'react'
import mail from '../../Assets/mail.svg'
import plane from '../../Assets/plane.svg'

const LoginBanner = () => {
    return (
        <div className='flex flex-col px-24 py-12 items-start justify-start gap-10 w-[60vw] bg-[#333] rounded-lg min-h-[60vh]'>
            <div>
                <h2 className='text-[40px] font-semibold w-[30vw] text-[#fff]'>Та үнэгүй 6-н сар хэрэглэхийг хүсвэл</h2>
                <br />
                <h2 className='text-[60px] text-[#fff]'>"</h2>
            </div>
            <p className='text-[#fff]'>Та үнэгүй 6-н сар хэрэглэхийг хүсвэл бидний мэйл-рүү өөрийн бүтээгдэхүүний танилцуулга болон холбоо барих мэдээллээ илгээгээрэй.</p>
            <p className='text-[#fff]'>Ирээдүйг одоо бүтээ.</p>
            <div className='flex gap-1 justify-center items-center border-b-[#22BDFF] border-b-[1px]'>
                <p className='text-[#22BDFF]'>Холбоо барих</p>
                <img src={mail} alt="" />
            </div>
            <div className='flex flex-col gap-5 bg-white rounded-xl w-full p-5'>
                <h2 className='text-[#333] text-[30px] font-semibold w-[80%]'>Бүх насаараа хэрэглэх нэрийн хууласны QR код </h2>
                <p className='w-[70%]'>template.mn-д бүртгүүлсэн 100-н хэрэглэгчид нэрийн хуудсыг үнэгүй бэлэглэж байна </p>
                <div>
                    <div className='flex gap-1 justify-center items-center w-[max-content] px-1 border-b-[#22BDFF] border-b-[1px]'>
                        <p className='text-[#22BDFF]'>Бүртгүүлэх</p>
                        <img src={plane} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginBanner
